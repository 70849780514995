import styled from "styled-components";
import { useFT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { Shimmer } from "~/components/atoms";
import type { ITableOptions } from "@sablier/v2-types";
import { Box as RowBoxPartial, Wrapper as RowWrapperPartial } from "../Row";
import * as Element from "./Element";

const WrapperPartial = styled.div<{ rows?: number }>`
  ${(props) => props.theme.styles.column}
  & {
    width: 100%;
    min-height: calc(
      var(--table-option-row) * 1px * ${(props) => props.rows || 2}
    );
    &[data-fixed="true"] {
      height: calc(
        var(--table-option-row) * 1px * ${(props) => props.rows || 2}
      );
    }
  }
`;

const Edge = styled.div`
  width: 100%;
  height: 100%;
  padding: calc(${(props) => props.theme.sizes.edge} * 1);
  &:not(:last-child) {
    padding-bottom: 0;
  }
`;

const Box = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: calc(var(--table-option-row) * 1px);
    text-align: center;
    border: 2px solid ${(props) => props.theme.colors.dark300};
    border-radius: var(--table-radius);
  }
`;

const Column = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    row-gap: calc(${(props) => props.theme.sizes.edge} * 2 / 3);
    justify-content: center;
  }
`;

const Title = styled.div`
  & > p {
    ${(props) => props.theme.styles.textPlaceholder}
  }
`;

const Error = styled.div`
  & > p {
    ${(props) => props.theme.styles.textPlaceholder}
    & {
      color: ${(props) => props.theme.colors.yellow};
    }
  }
`;

const RowWrapper = styled(RowWrapperPartial)``;

const RowBox = styled(RowBoxPartial)`
  background-color: ${(props) => props.theme.colors.dark200};
`;

const Horizontal = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: calc(${(props) => props.theme.sizes.edge} * 1);
`;

const Wrapper = styled(WrapperPartial)`
  &[data-appearance="spaced"] {
    margin-top: var(--table-gap-spaced);
    border-radius: var(--table-radius);
    background-color: ${(props) => props.theme.colors.dark150};
  }

  ${(props) => props.theme.medias.minLG} {
    ${RowBox} {
      padding-left: min(
        ${(props) => props.theme.sizes.edge},
        calc(
          (
              var(--table-option-row) * 1px - 2 * 18px -
                calc(${(props) => props.theme.sizes.edge} * 2 / 3)
            ) / 2
        )
      );
    }
  }

  ${(props) => props.theme.medias.maxLG} {
    ${Edge} {
      padding: 4px;
    }
  }
  ${(props) => props.theme.medias.maxSM} {
    ${Horizontal} {
      grid-gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    }
    ${Box} {
      border-radius: 4px;
    }
  }
`;

function EmptyClassic({
  appearance,
  error,
  value: _value,
}: {
  appearance?: ITableOptions["appearance"];
  error?: string;
  value?: string;
}) {
  const value = useFT(_value, "structs.noResults");
  return (
    <Wrapper data-appearance={appearance} data-fixed={"true"}>
      <Edge>
        <Box>
          <Title>
            <p>{value}</p>
          </Title>
          {!_.isNilOrEmptyString(error) ? (
            <Error>
              <p>{error}</p>
            </Error>
          ) : (
            false
          )}
        </Box>
      </Edge>
    </Wrapper>
  );
}

function EmptyRecipient({
  appearance,
  error,
  value: _value,
}: {
  appearance?: ITableOptions["appearance"];
  error?: string;
  value?: string;
}) {
  const value = useFT(_value, "structs.noResults");
  return (
    <Wrapper data-appearance={appearance}>
      <Edge>
        <Box>
          <Title>
            <p>{value}</p>
          </Title>
          {!_.isNilOrEmptyString(error) ? (
            <Error>
              <p>{error}</p>
            </Error>
          ) : (
            false
          )}
        </Box>
      </Edge>
      <Edge>
        <Horizontal>
          <Element.Recipient />
          <Element.Explore />
        </Horizontal>
      </Edge>
    </Wrapper>
  );
}

function EmptySender({
  appearance,
  error,
  value: _value,
}: {
  appearance?: ITableOptions["appearance"];
  error?: string;
  value?: string;
}) {
  const value = useFT(_value, "structs.noResults");
  return (
    <Wrapper data-appearance={appearance}>
      <Edge>
        <Box>
          <Title>
            <p>{value}</p>
          </Title>
          {!_.isNilOrEmptyString(error) ? (
            <Error>
              <p>{error}</p>
            </Error>
          ) : (
            false
          )}
        </Box>
      </Edge>
      <Edge>
        <Horizontal>
          <Element.Sender />
          <Element.Explore />
        </Horizontal>
      </Edge>
    </Wrapper>
  );
}

function Empty({
  empty,
  ...props
}: {
  appearance?: ITableOptions["appearance"];
  error?: string | undefined;
  value?: string;
  empty: ITableOptions["empty"];
}) {
  if (empty === "vesting-recipient") {
    return <EmptyRecipient {...props} />;
  }

  if (empty === "vesting-sender") {
    return <EmptySender {...props} />;
  }

  return <EmptyClassic {...props} />;
}

function Loading({
  appearance,
  rows = 2,
}: {
  appearance?: ITableOptions["appearance"];
  rows?: number;
}) {
  return (
    <Wrapper data-fixed={"true"} rows={rows}>
      {[...Array(rows).keys()].map((i) => (
        <RowWrapper
          data-appearance={appearance}
          data-component={"row-wrapper"}
          key={i}
        >
          <RowBox data-component={"row"}>
            <Column>
              <Shimmer
                purpose={"value"}
                width={120}
                background={"dark300"}
                foreground={"dark700"}
              />
              <Shimmer
                purpose={"value"}
                width={220}
                background={"dark300"}
                foreground={"dark700"}
              />
            </Column>
          </RowBox>
        </RowWrapper>
      ))}
    </Wrapper>
  );
}

const Blocks = {
  Empty,
  Loading,
};

export default Blocks;
