import { useEffect } from "react";
import styled from "styled-components";
import { TableProvider, useTable } from "@sablier/v2-contexts";
import type { CellType } from "./Cells";
import type { ITable, ITableOptions, ITableRow } from "@sablier/v2-types";
import Body from "./Body";
import Footer from "./Footer";
import Header from "./Header";

const WrapperPartial = styled.div<{ options?: ITableOptions }>`
  ${(props) => props.theme.styles.column}
  & {
    --table-option-empty: ${(props) => props.options?.empty};
    --table-option-row: ${(props) =>
      props.options?.row || props.theme.sizes.tableRow};
    --table-radius: ${(props) => props.theme.sizes.radius};
    --table-gap-spaced: ${(props) => props.theme.sizes.tableGapSpaced};
    width: 100%;
    color: ${(props) => props.theme.colors.gray200};
  }
`;

const Box = styled.div`
  width: 100%;
  box-shadow: 0px 15px 32px -10px ${(props) => props.theme.colors.dark050};
`;

const Wrapper = styled(WrapperPartial)`
  &[data-appearance="spaced"] {
    ${Box} {
      box-shadow: none;
    }
  }
`;

function TableComponent({ className, ...props }: ITable) {
  const { set } = useTable();

  useEffect(() => {
    set(props.data);
  }, [props.data, set]);

  return (
    <Wrapper
      className={className}
      data-appearance={props.data.options?.appearance}
      data-component={"table"}
      options={props.data.options || {}}
    >
      <Box>
        <Header />
        <Body />
      </Box>
      <Footer />
    </Wrapper>
  );
}

function Table(props: ITable) {
  return (
    <TableProvider>
      <TableComponent {...props} />
    </TableProvider>
  );
}

export default Table;

export type ISTableCell = CellType;
export type ISTableRow<T> = ITableRow & {
  cells: T;
};
export type ISTableRows<T> = ISTableRow<T>[];
export type { ITableOptions };
