import { ComponentProps, useMemo } from "react";
import styled from "styled-components";
import { _ } from "@sablier/v2-mixins";
import { Label } from "~/components/molecules";
import type { ThemeType } from "@sablier/v2-themes";
import type { ITableCell } from "@sablier/v2-types";

const Wrapper = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    width: 100%;
    padding: 0;
  }
`;

const Content = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    gap: calc(${(props) => props.theme.sizes.edge} * 1);
    width: 100%;
    height: 100%;
    max-width: 380px;
    padding: ${(props) => props.theme.sizes.edge};
    padding-inline: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
    border-radius: ${(props) => props.theme.sizes.radius};
    background-color: ${(props) => props.theme.colors.dark300};

    *[data-component="label"] {
      color: ${(props) => props.theme.colors.white};
      letter-spacing: 2px;
      text-transform: uppercase;

      label {
        font-size: 10pt;
      }
    }
  }
`;

const Accent = styled.div<{ accent: keyof ThemeType["colors"] }>`
  width: 4px;
  height: 44px;
  border-radius: 2px;
  background: linear-gradient(
    180deg,
    ${(props) => props.theme.colors[props.accent || "white"]},
    ${(props) => props.theme.colors.transparent}
  );
`;

const Column = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    gap: 3px;
  }
`;

const Description = styled.div`
  & > p {
    ${(props) => props.theme.styles.textParagraph}
    & {
      color: ${(props) => props.theme.colors.gray400};
    }
  }
`;

export interface Props {
  data: ITableCell & {
    value: {
      accent: keyof ThemeType["colors"];
      title: ComponentProps<typeof Label>;
      description?: string;
    };
  };
}

function Section({ data }: Props) {
  const value = useMemo(() => data.value, [data]);

  return (
    <Wrapper>
      <Content>
        <Accent accent={value.accent} />
        <Column>
          <Label {...value.title} />
          {!_.isNilOrEmptyString(value.description) && (
            <>
              <Description>
                <p>{_.capitalize(value.description)}</p>
              </Description>
            </>
          )}
        </Column>
      </Content>
    </Wrapper>
  );
}

export default Section;
