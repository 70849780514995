import styled from "styled-components";
import {
  ArrowUpRightIcon,
  PlusIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import AssetCalendar from "@sablier/v2-assets/3d/calendar-orange.png";
import AssetClock from "@sablier/v2-assets/3d/clock-orange.png";
import AssetETH from "@sablier/v2-assets/3d/coin-eth-orange.png";
import AssetHourglass from "@sablier/v2-assets/3d/hourglass-d2-gold.png";
import { chains, links, routes } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import Image from "next/image";
import { Button } from "~/components/molecules";

const WrapperPartial = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    grid-column: span 1;
    position: relative;
    border: 2px solid ${(props) => props.theme.colors.border};
    border-radius: var(--table-radius);
    background-color: ${(props) => props.theme.colors.dark100};
  }
`;

const Content = styled.div`
  ${(props) => props.theme.styles.column};
  & {
    z-index: ${(props) => props.theme.sizes.zIndexOver};
    grid-gap: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
    width: 100%;
    max-width: 440px;
    min-height: 100%;
    padding: calc(${(props) => props.theme.sizes.edge} * 2)
      calc(${(props) => props.theme.sizes.edge} * 3 / 2);
    padding-right: 100px;
  }
`;

const Art = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    position: absolute;
    top: calc(${(props) => props.theme.sizes.edge} * -1);
    right: 0;
    z-index: ${(props) => props.theme.sizes.zIndexUnder};
    width: 200px;
    height: 200px;
    overflow: hidden;
  }
`;

const Title = styled.div`
  & > p {
    ${(props) => props.theme.styles.textBase}
    & {
      color: ${(props) => props.theme.colors.white};
      font-size: 16pt;
    }
  }
`;

const Description = styled.div`
  flex: 1;
  & > p {
    ${(props) => props.theme.styles.textParagraph}
    & {
      color: ${(props) => props.theme.colors.gray200};
    }
  }
`;

const Actions = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    gap: calc(${(props) => props.theme.sizes.edge} * 1);
  }
`;

const Hourglass = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    position: absolute;
    top: 0;
    right: -60px;
    width: 100%;
    height: 100%;
    transform: rotate(-15deg);

    img {
      object-fit: contain;
      object-position: top center;
    }
  }
`;

const Calendar = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    position: absolute;
    top: 5px;
    right: -50px;
    width: 100%;
    height: 100%;

    img {
      object-fit: contain;
      object-position: top center;
    }
  }
`;

const Clock = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    position: absolute;
    top: 2px;
    right: -60px;
    width: 90%;
    height: 90%;
    transform: rotate(5deg) scaleX(-1);

    img {
      object-fit: contain;
      object-position: top center;
    }
  }
`;

const Coin = styled(Clock)`
  transform: rotate(-10deg);
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxMD} {
    grid-column: span 2;
    ${Content} {
      padding-right: 120px;
    }
  }
  ${(props) => props.theme.medias.maxSM} {
    ${(props) => props.theme.styles.column}
    & {
      grid-column: span 2;
      border-radius: 4px;
      ${Content} {
        order: 2;
        height: auto;
        min-height: auto;
        padding: calc(${(props) => props.theme.sizes.edge} * 1);
      }
      ${Description} {
        flex: none;
      }

      ${Art} {
        position: relative;
        top: inherit;
        right: inherit;
        order: 1;
        justify-content: flex-start;
        width: 100%;
        height: 120px;
        padding-top: calc(${(props) => props.theme.sizes.edge} * 1);
      }

      ${Hourglass}, ${Coin}, ${Clock}, ${Calendar} {
        position: relative;
        top: inherit;
        right: inherit;
        width: 100%;
        height: 100%;
        transform: none;
        img {
          transform: none;
          object-position: center left;
        }
      }
    }
  }
`;

function Recipient() {
  const { t } = useT();
  return (
    <Wrapper>
      <Content>
        <Title>
          <p>{t("structs.requestStream")}</p>
        </Title>
        <Description>
          <p>{t("structs.exploreRecipient")}</p>
        </Description>
        <Button
          accent={"primary"}
          appearance={"gradient"}
          isMini
          purpose={"internal"}
          to={routes.client.pages.vesting.gallery.builder()}
          right={PlusIcon}
          title={_.capitalize(t("words.create"))}
        />
      </Content>
      <Art>
        <Coin>
          <Image
            alt={"Coin"}
            src={AssetETH}
            fill
            priority
            sizes={"(min-width: 300px) 50vw,(min-width: 1000px) 20vw, 100vw"}
          />
        </Coin>
      </Art>
    </Wrapper>
  );
}

function Sender() {
  const { t } = useT();
  return (
    <Wrapper>
      <Content>
        <Title>
          <p>{t("structs.createStreams")}</p>
        </Title>
        <Description>
          <p>{t("structs.exploreSender")}</p>
        </Description>
        <Button
          accent={"primary"}
          appearance={"gradient"}
          isMini
          purpose={"internal"}
          to={routes.client.pages.vesting.gallery.builder()}
          right={PlusIcon}
          title={_.capitalize(t("words.create"))}
        />
      </Content>
      <Art>
        <Hourglass>
          <Image
            alt={"Hourglass"}
            src={AssetHourglass}
            fill
            priority
            sizes={"(min-width: 300px) 50vw,(min-width: 1000px) 20vw, 100vw"}
          />
        </Hourglass>
      </Art>
    </Wrapper>
  );
}

function Videos() {
  const { t } = useT();
  return (
    <Wrapper>
      <Content>
        <Title>
          <p>{t("structs.watchTutorials")}</p>
        </Title>
        <Description>
          <p>{t("descriptions.watchTutorials")}</p>
        </Description>
        <Actions>
          <Button
            accent={"dark300"}
            appearance={"solid"}
            isMini
            purpose={"external"}
            to={links.docs.v2}
            right={ArrowUpRightIcon}
            title={_.startCase(t("structs.viewDocs"))}
          />
          <Button
            accent={"dark300"}
            appearance={"solid"}
            isMini
            purpose={"external"}
            to={links.docs.guides}
            right={VideoCameraIcon}
            title={_.startCase(t("structs.watchTutorials"))}
          />
        </Actions>
      </Content>
      <Art>
        <Calendar>
          <Image
            alt={"Calendar"}
            src={AssetCalendar}
            fill
            priority
            sizes={"(min-width: 300px) 50vw,(min-width: 1000px) 20vw, 100vw"}
          />
        </Calendar>
      </Art>
    </Wrapper>
  );
}

function Explore() {
  const { t } = useT();
  return (
    <Wrapper>
      <Content>
        <Title>
          <p>{_.capitalize(t("words.explore"))}</p>
        </Title>
        <Description>
          <p>{t("structs.exploreEmpty")}</p>
        </Description>
        <Actions>
          <Button
            accent={"dark300"}
            appearance={"solid"}
            isMini
            purpose={"internal"}
            to={links.v2.clientDemo}
            title={_.upperFirst(t("structs.openExample"))}
            titleMedium={_.capitalize(t("words.example"))}
          />
          <Button
            accent={"dark300"}
            appearance={"solid"}
            isMini
            purpose={"internal"}
            to={routes.client.tabs.vesting.search.builder({
              c: chains.mainnet.chainId.toString(),
            })}
            title={t("structs.exploreEthereum")}
            titleMedium={_.capitalize(t("words.explore"))}
          />
        </Actions>
      </Content>
      <Art>
        <Clock>
          <Image
            alt={"Clock"}
            src={AssetClock}
            fill
            priority
            sizes={"(min-width: 300px) 50vw,(min-width: 1000px) 20vw, 100vw"}
          />
        </Clock>
      </Art>
    </Wrapper>
  );
}

export { Explore, Recipient, Sender, Videos };
