import { useCallback, useMemo } from "react";
import styled from "styled-components";
import { useResolvedENS, useShortAddressOrName } from "@sablier/v2-hooks";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { Label, Tooltip } from "~/components/molecules";
import type { ITableCell } from "@sablier/v2-types";
import type { ComponentProps } from "react";

const Wrapper = styled.div`
  grid-column: auto;
  flex-grow: 0;
  padding-inline: calc(${(props) => props.theme.sizes.edge} * 3 / 4);
`;

const Inner = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    row-gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    justify-content: center;
    height: 100%;
    & > * {
      min-height: 18px;
    }
  }
`;

const Content = styled.div`
  ${(props) => props.theme.styles.row}
  & > p {
    ${(props) => props.theme.styles.textElement}
  }
`;

export interface Props {
  data: ITableCell & {
    value: {
      label?: ComponentProps<typeof Label> | string;
      sender?: string;
      recipient?: string;
      chainId: number;
      purpose: "recipient" | "sender" | "public";
    };
  };
}

function Public({ data }: Props) {
  const value = useMemo(() => data.value, [data]);
  const chainId = useMemo(() => value.chainId, [value]);
  const recipient = useResolvedENS({ address: value.recipient, chainId });
  const sender = useResolvedENS({ address: value.sender, chainId });

  const from = useShortAddressOrName(sender.data || value.sender, 8, -6);
  const to = useShortAddressOrName(recipient.data || value.recipient, 10, -6);
  const { t } = useT();

  const top = useMemo(
    () => ({
      value: `${_.capitalize(t("words.from"))}: ${from}`,
      tooltip: {
        isMini: true,
        maxWidth: "400px",
        mouseEnterDelay: 1,
        value: value.sender,
      },
    }),
    [from, value, t],
  );

  const bottom = useMemo(
    () => ({
      value: `${_.capitalize(t("words.to"))}: ${to}`,
      tooltip: {
        isMini: true,
        maxWidth: "400px",
        mouseEnterDelay: 1,
        value: value.recipient,
      },
    }),
    [to, value, t],
  );

  return (
    <Wrapper>
      <Inner>
        <Label value={top.value} tooltip={top.tooltip} />
        <Tooltip {...bottom.tooltip}>
          <Content>
            <p>{bottom.value}</p>
          </Content>
        </Tooltip>
      </Inner>
    </Wrapper>
  );
}

function Private({ data }: Props) {
  const value = useMemo(() => data.value, [data]);
  const chainId = useMemo(() => value.chainId, [value]);
  const address = useMemo(
    () => (value.purpose === "sender" ? value.recipient : value.sender),
    [value],
  );
  const resolution = useResolvedENS({ address, chainId });
  const display = useShortAddressOrName(
    resolution.data || address,
    value.purpose === "sender" ? 10 : 8,
    -6,
  );
  const { t } = useT();

  const prefix = useMemo(
    () =>
      value.purpose === "sender"
        ? `${_.capitalize(t("words.to"))}:`
        : `${_.capitalize(t("words.from"))}:`,
    [value.purpose, t],
  );

  const Bottom = useCallback(() => {
    const tooltip = {
      isMini: true,
      maxWidth: "400px",
      mouseEnterDelay: 1,
      value: address,
    };
    const value = `${prefix} ${display}`;

    return (
      <Tooltip {...tooltip}>
        <Content>
          <p>{value}</p>
        </Content>
      </Tooltip>
    );
  }, [address, display, prefix]);

  const Top = useCallback(() => {
    const label = value.label;
    if (_.isString(label)) {
      return <Label value={label} />;
    }
    if (_.has(label, "value")) {
      return <Label {...label} />;
    }
    return <></>;
  }, [value]);

  return (
    <Wrapper>
      <Inner>
        <Top />
        <Bottom />
      </Inner>
    </Wrapper>
  );
}

function AddressDeckCell({ data }: Props) {
  if (data.value.purpose === "public") {
    return <Public data={data} />;
  }

  return <Private data={data} />;
}

export default AddressDeckCell;
