import { useMemo } from "react";
import styled from "styled-components";
import { ArrowsPointingOutIcon } from "@heroicons/react/24/outline";
import { depropagate } from "@sablier/v2-utils";
import { Button, Icon } from "~/components/molecules";
import type { ITableCell } from "@sablier/v2-types";
import type { ComponentProps } from "react";
import Menu from "./Menu";

const Wrapper = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    padding-inline: calc(${(props) => props.theme.sizes.edge} * 3 / 4);
  }
`;

const Content = styled.div`
  ${(props) => props.theme.styles.row}
`;

const Info = styled.div`
  --size: 11.5pt;
  position: absolute;
  left: 4px;
  z-index: 10;
  width: 16px;
  height: 16px;
`;

const Chip = styled(Button)`
  &[data-mini="true"],
  &:not([data-mini="true"]) {
    --size: 11.5pt;
    --timing: 150ms;
    position: relative;
    gap: 0px;
    height: auto;
    padding: 2px 4px 2px 2px;
    border-radius: calc(${(props) => props.theme.sizes.buttonMini} / 2);
    ${Info} {
      & > * {
        opacity: 0;
        transform: translate(-10px);
        transition: transform var(--timing) ease-in,
          opacity var(--timing) ease-in;
      }
    }

    & > *[data-component="side"]:first-child {
      & > * {
        opacity: 1;
        transform: translate(0px);
        transition: transform var(--timing) ease-in,
          opacity var(--timing) ease-in;
      }
    }
  }
`;

const List = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    grid-column: auto;
    flex-wrap: wrap;
    gap: 4px;

    ${Chip} {
      &[data-mini="true"],
      &:not([data-mini="true"]) {
        &:hover,
        &:active {
          ${Info} {
            & > * {
              opacity: 1;
              transform: translate(0px);
              transition: transform var(--timing) ease-out,
                opacity var(--timing) ease-out;
            }
          }
          & > *[data-component="side"]:first-child {
            & > * {
              opacity: 0;
              transform: translate(10px);
              transition: transform var(--timing) ease-out,
                opacity var(--timing) ease-out;
            }
          }
        }
      }
    }
  }
`;

type Item = Omit<ComponentProps<typeof Button>, "isMini" | "isUnpadded">;

export interface Props {
  data: ITableCell & {
    value: Item[];
    menu?: ComponentProps<typeof Menu>["data"];
  };
}

function Chips({ data }: Props) {
  const menu = useMemo(() => data.menu, [data]);
  const value = useMemo(() => data.value, [data]);

  return (
    <Wrapper onClick={depropagate}>
      <Content>
        <Menu data={menu}>
          <List>
            {value.map((item, index) => (
              <Chip key={`${index}-${item.title}`} {...item} isMini>
                <Info>
                  <Icon value={ArrowsPointingOutIcon} />
                </Info>
              </Chip>
            ))}
          </List>
        </Menu>
      </Content>
    </Wrapper>
  );
}

export default Chips;
