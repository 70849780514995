import { useMemo } from "react";
import styled from "styled-components";
import { _ } from "@sablier/v2-mixins";
import { depropagate } from "@sablier/v2-utils";
import type { Props as ActionButton } from "./Action";
import type { ITableCell } from "@sablier/v2-types";
import Action from "./Action";

const Wrapper = styled.div`
  grid-column: auto;
  padding-inline: calc(${(props) => props.theme.sizes.edge} * 3 / 4);
`;

const Inner = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    height: 100%;
  }
`;

const Row = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    column-gap: calc(${(props) => props.theme.sizes.edge} * 3 / 4);
  }
`;

const Indicator = styled.div`
  flex-shrink: 0;
  height: 46px;
  margin-left: 4px;
  &:after {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: ${(props) => props.theme.gradients.primary};
  }
`;

export interface Props {
  data: ITableCell & {
    value: {
      buttons: ActionButton[];
      isIndicatorOn?: boolean;
    };
  };
}

function ActionsCell({ data }: Props) {
  const value = useMemo(() => data.value, [data]);
  const isIndicatorOn = useMemo(() => value.isIndicatorOn, [value]);
  const buttons = useMemo(() => value.buttons, [value]);

  return (
    <Wrapper onClick={depropagate}>
      <Inner>
        <Row>
          {buttons.map((b, i) => (
            <Action key={i} {...b} />
          ))}
        </Row>
        {isIndicatorOn && <Indicator data-component={"indicator"} />}
      </Inner>
    </Wrapper>
  );
}

export default ActionsCell;
