import styled from "styled-components";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { useTableElements } from "@sablier/v2-contexts";
import { _ } from "@sablier/v2-mixins";
import { Icon, Tooltip } from "~/components/molecules";
import type { ITableColumn } from "@sablier/v2-types";

type Attributes = {
  template: string;
};

const Wrapper = styled.div<Attributes>`
  display: grid;
  grid-template-columns: ${(props) => props.template};
  grid-gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
  width: 100%;
  height: 76px;
  padding-inline: calc(${(props) => props.theme.sizes.edge} * 1);
  border: 2px solid ${(props) => props.theme.colors.border};
  border-radius: var(--table-radius) var(--table-radius) 0 0;
  background-color: ${(props) => props.theme.colors.dark300};

  &[data-appearance="spaced"] {
    border-radius: var(--table-radius);
    box-shadow: 0px 15px 32px -10px ${(props) => props.theme.colors.dark050};
  }
`;

const Item = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    grid-column: auto;
    justify-content: center;
    height: 100%;
    padding-inline: calc(${(props) => props.theme.sizes.edge} * 3 / 4);
  }
`;

const Title = styled.div`
  ${(props) => props.theme.styles.row}

  & {
    --size: 19px;
    position: relative;
    gap: 4px;
    color: ${(props) => props.theme.colors.gray400};

    & > p {
      ${(props) => props.theme.styles.textElement}
      & {
        font-weight: 800;
        font-size: 10pt;
        letter-spacing: 2px;
        text-transform: uppercase;
      }
    }
  }
`;

function Column({ id, info, title }: ITableColumn) {
  return (
    <Item data-id={id} data-component={"header-cell"}>
      <Tooltip value={info} maxWidth={"210px"}>
        <Title>
          <p>{title}</p>
          {!_.isNilOrEmptyString(info) ? (
            <Icon value={InformationCircleIcon} />
          ) : (
            false
          )}
        </Title>
      </Tooltip>
    </Item>
  );
}

function Header() {
  const { columns, template, options } = useTableElements();

  return (
    <Wrapper
      data-appearance={options.appearance}
      data-component={"header"}
      template={template}
    >
      {columns.map((column) => (
        <Column key={column.id} {...column} />
      ))}
    </Wrapper>
  );
}

export default Header;
