import { CSSProperties, memo, useCallback, useMemo } from "react";
import styled from "styled-components";
import { useTableAccessors, useTableElements } from "@sablier/v2-contexts";
import { _ } from "@sablier/v2-mixins";
import { vendors } from "@sablier/v2-utils";
import type { MouseEvent, PropsWithChildren } from "react";
import Cells from "../Cells";

type Attributes = {
  template?: string;
};

export const Box = styled.div`
  position: relative;
  display: grid;
  width: 100%;
  height: calc(var(--table-option-row) * 1px);
  height: 100%;
  padding-inline: calc(${(props) => props.theme.sizes.edge} * 1);
  border-radius: 0px;
  background-color: ${(props) => props.theme.colors.dark150};
`;

export const Wrapper = styled.div<Attributes>`
  --table-row-hover: 0;
  --table-row-selected: ;
  position: relative;
  width: 100%;
  height: calc(var(--table-option-row) * 1px);

  &[data-appearance="spaced"] {
    padding-top: var(--table-gap-spaced);
  }

  &[data-section="true"] {
    --table-row-selected: 0;
    --table-row-hover: 0;
    background-color: transparent;
    &:hover,
    &:active {
      ${Box} {
        display: flex;
        background-color: transparent;
      }
    }
    ${Box} {
      display: flex;
      padding-inline: 0;
      background-color: transparent;
    }
  }

  &:not([data-section="true"]) {
    &:hover,
    &:active {
      --table-row-hover: 1;
      ${Box} {
        background-color: ${(props) => props.theme.colors.dark200};
      }
    }

    ${Box} {
      grid-template-columns: ${(props) => props.template || "auto"};
      grid-gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    }

    &[data-animated="true"] {
      ${Box} {
        ${(props) => props.theme.animations.fadeIn}
        & {
          animation-duration: 100ms;
        }
      }
    }

    &:not(:last-child) {
      ${Box} {
        border-bottom: 2px solid ${(props) => props.theme.colors.border};
      }
    }

    &[data-selected="true"] {
      --table-row-selected: 1;
      &:hover,
      &:active {
        --table-row-hover: 1;
        ${Box} {
          background-color: ${(props) => props.theme.colors.dark300};
        }
      }
      ${Box} {
        background-color: ${(props) => props.theme.colors.dark200};
      }
      &:not(:last-child) {
        ${Box} {
          border-bottom: 2px solid ${(props) => props.theme.colors.dark400};
        }
      }
    }

    &[data-appearance="spaced"] {
      ${Box} {
        border: none;
        border-radius: var(--table-radius);
        box-shadow: 0px 15px 32px -10px ${(props) => props.theme.colors.dark050};
      }
      &:not(:last-child),
      &[data-selected="true"]:not(:last-child) {
        ${Box} {
          border-bottom: none;
        }
      }
    }
  }
`;

interface Props {
  x: number;
  style?: CSSProperties;
}

function Row({ x, style }: PropsWithChildren<Props>) {
  const accessors = useTableAccessors();
  const { options, template, isAnimated } = useTableElements();

  const { getColumn, getRow, getInstruction } = accessors;
  const { cells, id, isSelected, isSection } = useMemo(
    () => getRow({ x }),
    [getRow, x],
  );

  const onClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (isSection) {
        return;
      }

      const onRowClick = getInstruction({ name: "onRowClick" });
      if (_.isFunction(onRowClick)) {
        onRowClick(id, event);
      }
    },
    [getInstruction, id, isSection],
  );

  return (
    <Wrapper
      data-appearance={options.appearance}
      data-animated={isAnimated}
      data-component={"row-wrapper"}
      data-selected={isSelected}
      data-section={isSection}
      data-id={id}
      onClick={onClick}
      template={template}
      style={style}
    >
      <Box data-component={"row"}>
        {_.toArray(cells).map((cell, y) => {
          try {
            const column = getColumn({ y });
            const cellId = `${id}-${column.id}`;
            const layout = isSection
              ? "Section"
              : _.get(column, "layout") || "Text";
            const Component = Cells[layout];

            const props = { data: cell, x, y };

            if (!_.isNil(Component)) {
              return <Component key={cellId} {...props} />;
            }
            return <Cells.Text key={cellId} {...props} />;
          } catch (error) {
            vendors.crash.log(error);
          }
          return null;
        })}
      </Box>
    </Wrapper>
  );
}

export default memo(Row);
