import styled, { CSSProperties } from "styled-components";
import { _ } from "@sablier/v2-mixins";
import { Button, Label, Tooltip } from "~/components/molecules";
import type { ComponentProps, PropsWithChildren } from "react";

const overrides: CSSProperties = {
  padding: "0",
  borderRadius: "0",
  maxWidth: "260px",
};

const Wrapper = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    gap: calc(${(props) => props.theme.sizes.edge} * 0.8);
    width: 100%;
    padding: calc(${(props) => props.theme.sizes.edge} * 1);
    border: 2px solid ${(props) => props.theme.colors.border};
    border-radius: ${(props) => props.theme.sizes.radius};
    background: ${(props) => props.theme.colors.dark050};
  }
`;

const Header = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    justify-content: space-between;
    width: 100%;
    padding-bottom: calc(${(props) => props.theme.sizes.edge} * 0);
  }
`;

const Divider = styled.div`
  ${(props) => props.theme.styles.dividerHorizontal};
`;

const Title = styled.div`
  & > p {
    ${(props) => props.theme.styles.textElement}
    & {
      color: ${(props) => props.theme.colors.gray100};
      font-size: 11pt;
    }
  }
`;

const Row = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    width: 100%;
    & > *:first-child {
      margin-right: auto;
    }

    p,
    label {
      font-size: 11pt;
    }
  }
`;

const Tip = styled.div`
  & > p {
    line-height: 1.5 !important;
    ${(props) => props.theme.styles.textParagraphMini}
    & {
      color: ${(props) => props.theme.colors.dark1000};
      font-size: 11pt;
    }
  }
`;

const Footer = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    width: 100%;
  }
`;

type Item = Omit<
  ComponentProps<typeof Button>,
  "isMini" | "isUnpadded" | "onClick" | "isPreview"
>;

interface Props {
  data?: {
    title: string;
    tip: string;
    items: {
      label: ComponentProps<typeof Label>;
      value: Item;
    }[];
  };
}

function Menu({ children, data }: PropsWithChildren<Props>) {
  if (_.isNil(data)) {
    return children;
  }

  return (
    <Tooltip
      arrow={false}
      color={"transparent"}
      placement={"bottomLeft"}
      trigger={"click"}
      container={overrides}
      value={
        <Wrapper>
          <Header>
            <Title>
              <p>{data.title}</p>
            </Title>
          </Header>
          <Divider />
          {data.items.map((item, index) => (
            <Row key={index}>
              <Label {...item.label} />
              <Button isPreview isUnpadded {...item.value} />
            </Row>
          ))}
          {!_.isNilOrEmptyString(data.tip) && (
            <>
              <Divider />
              <Footer>
                <Tip>
                  <p>{data.tip}</p>
                </Tip>
              </Footer>
            </>
          )}
        </Wrapper>
      }
    >
      {children}
    </Tooltip>
  );
}

export default Menu;
